// Generated by Framer (2edebc7)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, Container, getFonts, Stack, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import Navigation from "../canvasComponent/GEoi1Pm9r";
const NavigationFonts = getFonts(Navigation);

const cycleOrder = ["wiMMFlXIG"];

const breakpoints = {}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"wiMMFlXIG": "framer-v-2iyou3"};

if (isBrowser) {
                    removeHiddenBreakpointLayers("wiMMFlXIG", breakpoints, variantClassNames);
            }

const humanReadableVariantMap = {};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "wiMMFlXIG", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "wiMMFlXIG", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated className={cx("framer-ls3tn")} style={{"display": "contents", "pointerEvents": pointerEvents ?? undefined}}>
<Stack {...restProps} className={cx("framer-2iyou3", className)} style={{...style}} name="Desktop"  direction="vertical" distribution="start" alignment="center" gap={60} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "100%", "height": "100%", "padding": "0px 48px 0px 48px"}} ref={ref} {...addVariantProps("wiMMFlXIG")}><Stack className="framer-q6ejel"   background={null} direction="vertical" distribution="center" alignment="center" gap={0} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "100%", "height": "auto", "padding": "0px 0px 0px 0px", "maxWidth": 744}} {...addVariantProps("WATyJh9pf")}><Container className="framer-12esu2f-container"  {...addVariantProps("Pf5K0uruL-container")}><Navigation width="100%" height="100%" layoutId="Pf5K0uruL" id="Pf5K0uruL" variant="fFdGd6igW" style={{"width": "100%", "height": "100%"}} {...addVariantProps("Pf5K0uruL")}/></Container></Stack></Stack>
<div id="overlay"/>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-ls3tn [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ls3tn .framer-2iyou3 { position: relative; overflow: hidden; width: 1200px; height: 1000px; background-color: #101219; }", ".framer-ls3tn .framer-q6ejel { position: relative; overflow: visible; max-width: 744px; width: 100%; height: min-content; flex: none; }", ".framer-ls3tn .framer-12esu2f-container { position: relative; width: 100%; height: 80px; flex: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1000
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FrameroSqiE1i1k: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FrameroSqiE1i1k;

FrameroSqiE1i1k.displayName = "Extra";

FrameroSqiE1i1k.defaultProps = {"width": 1200, "height": 1000};

addFonts(FrameroSqiE1i1k, [...NavigationFonts]);